<template>
  <div v-if="workflow">
    <div class="row">
      <div class="col-auto">
        <ol class="breadcrumb breadcrumb-arrows mb-3" aria-label="breadcrumbs">
          <li class="breadcrumb-item">
            <router-link :to="`/reputation/workflows/`">
              Workflows
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            <a href="#"> {{ workflow.name }} </a>
          </li>
        </ol>
      </div>
      <div class="col">
        <router-link
          class="btn btn-primary-outline btn-sm ms-1"
          :to="`/reputation/workflow/new?supersedes=${workflow.id}`"
        >
          Edit
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <render-workflow
          :editable="false"
          :workflow="workflow"
        ></render-workflow>
      </div>
    </div>
  </div>
</template>

<script>
import ReputationManagementService from "../../services/ReputationManagementService";
import RenderWorkflow from "./Workflow/RenderWorkflow.vue";
export default {
  name: "reputation.workflow.detail",
  components: { RenderWorkflow },
  data: () => {
    return {
      workflow: null,
    };
  },
  methods: {
    async loadWorkflow() {
      this.workflow = await ReputationManagementService.getWorkflow(
        this.$auth.activeCompany,
        this.$route.params.id
      );
    },
  },
  async mounted() {
    await this.loadWorkflow();
  },
};
</script>
